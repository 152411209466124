
/*
getBoundingClientRect
IntersectionObserver
*/

function getType(obj) {
    return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();
}

function ElScrollInView(obj) {
    this.init(obj);
};

ElScrollInView.prototype = {
    init(obj) {
        this.el = this.setEl(obj.el);
        this.handler = obj.handler;
        this.root = obj.root ? document.querySelector(obj.root) : window;
        this.fps = obj.fps || 24;

        this.root.addEventListener('scroll', this.scrollWatcher.bind(this));
        this.scrollWatcher();
    },
    setEl(el) {
        var res = [];
        if(getType(el) === 'string') {
            res = document.querySelectorAll(el);
        } else if (getType(el) === 'array') {
            el.forEach(item => {
                res.push.apply(res, this.setEl(item));
            })
        } else if(getType(el) === 'nodelist') {
            res = el;
        } else {
            res.push(el);
        }

        return res;
    },
    scrollWatcher() {
        this.throttle(() => {
            this.el.forEach(item => {
                this.inViewCheck(item);
            });
        });
    },
    inViewCheck(el) {
        var bcr = el.getBoundingClientRect();
        if(bcr.bottom > 0 && bcr.top < window.innerHeight) {
            this.handler(true, el);
        } else {
            this.handler(false, el);
        }
    },
    throttle(cb) {
        if(this.lock) return;

        cb();
        this.lock = true;
        setTimeout(() => {
            this.lock = false;
        }, 1000 / this.fps);
    },
    destroy() {
        this.root.removeEventListener('scroll', this.scrollWatcher);
    }
};

export default ElScrollInView;