<template>
    <div
        class="section-block"
        :class="layout"
    >
        <div class="text">
            <div class="title">
                {{data.title}}
            </div>

            <div class="list">
                <div
                class="item"
                v-for="(item, index) in data.list"
                :key="index"
                >
                    {{item}}
                </div>
            </div>
        </div>

        <div class="pic">
            <img
                v-for="(item, index) in data.pic"
                :src="item"
                :key="index"
                alt=""
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        layout: {
            type: String,
            default: 'left',
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@css/var.scss';

.section-block {
    height: 520px;
    position: relative;
    width: 1200px;
    margin: 0 auto;

    .text, .pic{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .text {

        .title {
            font-size: 32px;
            color: #252525;
        }

        .list {
            margin-top: 45px;

            .item {
                line-height: 1em;
                color: #252525;
                position: relative;
                padding-left: 10px;

                &:before {
                    content: '';
                    display: block;
                    width: 6px;
                    height: 6px;
                    background: $primaryColor;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                & + .item{
                    margin-top: 1em;
                }
            }
        }
    }

    .pic{
        position: absolute;
        height: 452px;
        width: 50%;
        top: 50%;
        transform: translateY(-50%);

        img{
            height: 312px;
            position: absolute;
            left: 0;
            top: 0;
            box-shadow: #CAD1DF 0 0 20px;
            transition: all .1s;

            &:hover{
                transform: translateY(-30px);
            }
        }

        img:nth-child(1) {
            top: 0;
            left: 0;
        }

        img:nth-child(2) {
            left: 70px;
            top: 70px;
        }

        img:nth-child(3) {
            left: 140px;
            top: 140px;
        }
    }

    &.left {
        .text {
        }

        .pic{
            left: 50%;
        }
    }

    &.right {
        .text {
            left: 50%;
        }

        .pic{
        }
    }
}
</style>