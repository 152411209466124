<template>
    <section 
    class="download" 
    :class="{fixed}"
    >
        <div class="content">
            <h3 class="wow tada">
                {{CONFIG_PROJECT.title}}，让投标工作更简单
            </h3>

            <div class="btn-row">
                <!-- <a class="btn-item primary" :href="CONFIG_PROJECT.downloadUrl.exe" :download="CONFIG_PROJECT.title">
                    下载安装包
                </a>

                <a class="btn-item" :href="CONFIG_PROJECT.downloadUrl.zip" :download="CONFIG_PROJECT.title">
                    免安装
                </a> -->
                <div class="btn wow slideInUp" @click="clickHandler" v-czc="['落地页', '点击按钮' , '免费试用',0 ,'main-btn-freetouse']">免费使用</div>
            </div>
        </div>
    </section>
</template>

<script>
import CONFIG_PROJECT from '@config/project';

import { mapActions } from 'vuex';
export default {
    props: {
        fixed: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            CONFIG_PROJECT,
        };
    },
    computed: {
        
    },
    methods: {
        ...mapActions({
            showLogin: 'global-dialog/showLogin',
        }),
        clickHandler () {

            // if(this.$cookies.get('SERIAL_CODE')){
            //     this.goto('/home');
            // }else{
            //     this.showLogin(() => {
            //         window.location.assign('/home');
            //     });
            // }

            window.location.assign('/home');
        }
    },
    mounted() {
        
    }
}
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';  

    .download{
        background: $primaryColor;
        padding: 28px 0 24px;
        text-align: center;

        &.fixed{
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
        }

        h3{
            text-align: center;
            font-size: 24px;
            color: white;
        }

        .btn-row{
            font-size: 20px;
            color: white;
            margin-top: 20px;
            display: flex;
            align-content: center;
            justify-content: center;

            .btn{
                width: 228px;
                height: 45px;
                line-height: 45px;
                background: rgba(245, 154, 35, 1);
                border-radius: 5px;
                transition: all .5s;
                cursor: pointer;
            }
            .btn:hover{
                background: $primaryColor8;
                color: white;
            }

            .btn-item{
                display: inline-block;
                padding: 14px 40px;
                cursor: pointer;
                border-radius: 4px;
                transition: all .1s;
                border: 1px solid white;
                line-height: 1em;

                background: $primaryColor;
                color: white;

                & + .btn-item{
                    margin-left: 20px;
                }

                &:hover{
                    background: $primaryColor8;
                    color: white;
                }

                &.primary{
                    background: white;
                    color: $primaryColor;

                    &:hover{
                        background: $primaryColor8;
                        color: white;
                    }
                }
            }
        }
    }
</style>