<template>
    <div class="right-nav-con">
        <div class="nav-item">
            <div class="icon iconfont">
                &#xec2e;
            </div>

            <div class="popup">
                <div class="row">
                    {{$store.state.config.tel}}
                </div>
                <div class="row">
                    (工作时间：{{$store.state.config.workTime}})
                </div>
            </div>
        </div>

        <div class="nav-item">
            <div class="icon iconfont">
                &#xe64f;
            </div>

            <div class="popup">
                <img src="~@images/about/wx-qrcode.png" alt="">
            </div>
        </div>

        <!-- <div class="nav-item">
            <el-tooltip effect="light" content="会员中心" placement="left">
                <a class="icon iconfont" href="/member">
                    &#xe7aa;
                </a>
            </el-tooltip>
        </div>

        <div class="nav-item">
            <el-tooltip effect="light" content="个人中心" placement="left">
                <div class="icon iconfont" @click="userHandler">
                    &#xe61c;
                </div>
            </el-tooltip>
        </div> -->
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    methods: {
        ...mapActions({
            showLogin: 'global-dialog/showLogin',
        }),
        userHandler() {
            const SERIAL_CODE = this.$cookies.get('SERIAL_CODE');

            if (!SERIAL_CODE) {
                this.showLogin(() => {
                    this.userHandler();
                });
            } else {
                this.goto(this.$store.state.defaultUserPage);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';

    .right-nav-con{
        position: fixed;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);

        .nav-item{
            position: relative;

            & + .nav-item{
                margin-top: 8px;
            }

            .icon{
                display: block;
                width: 50px;
                height: 50px;
                background: white;
                border: solid 1px #c9c9c9;
                text-align: center;
                line-height: 50px;
                color: #666;
                cursor: pointer;
                font-size: 20px;

                &:hover{
                    background-color: $primaryColor;
                    color: white;

                    & + .popup{
                        display: block;
                    }
                }
            }

            .popup{
                $width: 200px;

                width: $width;
                text-align: center;
                padding: 16px 24px;
                background-color: #ffffff;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.07);
                border-radius: 6px;
                position: absolute;
                left: -($width + 12px);
                top: -8px;
                display: none;

                img{
                    width: 100%;
                }
            }
        }
    }
</style>