<template>
    <div class="index">
        <section class="banner">
            <div class="content">
                <div class="slogan">
                    <h1>
                        {{CONFIG_PROJECT.title}}，让投标工作更简单
                    </h1>
                    <div class="sub">
                        {{CONFIG_PROJECT.title}}客户端是为投标人量身定做的一款投标工具
                    </div>

                    <div class="btn-row" id="btn-downloads-t">
                        <a 
                        class="btn-item primary" 
                        :href="getClientDownloadUrl()" 
                        :download="CONFIG_PROJECT.downloadUrl.exe.filename"
                        v-czc="['落地页', '点击按钮' , '下载安装包',0 ,'main-btn-download-exe']"
                        >
                            下载安装包
                        </a>

                        <!-- <a 
                        class="btn-item" 
                        :href="getClientDownloadUrl('zip')" 
                        :download="CONFIG_PROJECT.downloadUrl.zip.filename"
                        v-czc="['落地页', '点击按钮' , '下载免安装',0 ,'main-btn-download-zip']"
                        >
                            免安装
                        </a> -->

                        <el-popover
                        style="margin-left:20px"
                        placement="right"
                        trigger="hover">
                            <div class="wxmp-con">
                                <img src="/images/wx-mp-qrcode.png" alt="">

                                <div class="text">
                                    微信扫码打开小程序
                                </div>
                            </div>

                            <div class="btn-item" slot="reference">
                                微信小程序
                            </div>
                        </el-popover>

                        <a 
                        class="btn-item" 
                        style="margin-left:20px"
                        href="/home" 
                        v-czc="['落地页', '点击按钮' , '网页端',0 ,'main-btn-web']"
                        >
                            使用网页端
                        </a>
                    </div>
                </div>

                <div class="icon">
                    <img class="breath" src="@images/index/banner-ib.png" alt=""/>
                    <img class="breath" src="@images/index/banner-ibi.png" alt=""/>
                    <img src="@images/index/banner-i.png" alt=""/>
                </div>
            </div>
        </section>

        <section class="positive bg-light section">
            <div class="content wow rubberBand">
                <div class="info-row" v-for="(row, rowIndex) in info" :key="rowIndex">
                    <div class="title">
                        {{row.title}}
                    </div>

                    <div class="list">
                        <a 
                        class="list-item" 
                        v-for="(item, index) in row.list" 
                        :key="index"
                        :href="item.path"
                        target="_self"
                        >
                            <div class="head">
                                <i class="iconfont" :style="{color:item.color}" v-html="item.icon"></i>
                                <div class="text">
                                    {{item.name}}
                                </div>
                            </div>

                            <div class="body">
                                <div v-for="(text, textIndex) in item.body" :key="textIndex">
                                    {{text}}
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <section class="wow fadeInDown">
            <section-block
                :data="bigDataData"
            ></section-block>
        </section>

        <section class="bg-light wow fadeInDown">
            <section-block
                :data="analysisData"
                layout="right"
            ></section-block>
        </section>

        <section class="wow fadeInDown">
            <section-block
                :data="toolData"
            ></section-block>
        </section>

        <section-download id="btn-downloads-b"></section-download>

        <my-footer></my-footer>

        <right-nav></right-nav>

        <section-download 
            v-if="fixedDownloadController"
            fixed
        ></section-download>
    </div>
</template>

<script>
import CONFIG_PROJECT from '@config/project';

import SectionDownload from './index/sections/download';
import SectionBlock from './index/sections/block';

import RightNav from './index/components/right-nav';

import appNode from '@js/app-node';
import ElScrollInView from '@js/ElScrollInView';

export default {
    components: { 
        SectionDownload,
        SectionBlock,
        RightNav,
    },
    data: () => ({
        wow: null,

        CONFIG_PROJECT,

        info: [
            {
                title: '投标工作更便捷',
                list: [
                    {
                        name: '高效投标工具',
                        icon: '&#xe80e;',
                        color: '#1bd062',
                        body: [
                            '优质三方投标工具',
                            '投标工作效率翻倍',
                        ],
                        path: '/home/nav?type=实用工具',
                    },
                    {
                        name: '权威投标查询网站导航',
                        icon: '&#xe716;',
                        color: '#ffaa0c',
                        body: [
                            '政府公开投标网站',
                            '应有尽有',
                        ],
                        path: '/home/nav?type=常用网站',
                    },
                    {
                        name: '招标项目',
                        icon: '&#xe610;',
                        color: '#ff5050',
                        body: [
                            '项目信息全网覆盖',
                            '第一时间获取标讯内容',
                        ],
                        path: '/home',
                    },
                    {
                        name: '电子投标服务',
                        icon: '&#xf8b5;',
                        color: '#02D5FF',
                        body: [
                            '多平台全流程电子投标服务',
                            '一站式办理',
                        ],
                        path: '/application/electronic-bidding',
                    },
                    {
                        name: '标书制作 标书检查',
                        icon: '&#xe60f;',
                        color: '#edc700',
                        body: [
                            '专业严谨的标书行业人员',
                            '让您安心投标',
                        ],
                        path: '/application/file-agent',
                    },
                    {
                        name: '全国CA代办',
                        icon: '&#xe64c;',
                        color: '#00dab2',
                        body: [
                            '专业安全便捷高效',
                            '让您更省心',
                        ],
                        path: '/application/ca-agent',
                    },
                ],
            },
            {
                title: '投标商机轻松获取',
                list: [
                    {
                        name: '商机人脉推送',
                        icon: '&#xe641;',
                        color: '#ff5050',
                        body: [
                            '找项目  找人脉',
                            '轻松搞定',
                        ],
                        path: '/business-contact',
                    },
                    {
                        name: '监控对手及业主',
                        icon: '&#xe60d;',
                        color: '#00DCCF',
                        body: [
                            '对手/业主动态变化',
                            '实时查看',
                        ],
                        path: '/home?recommend_type=projectManager',
                    },
                    {
                        name: '企业基本及招投标信息',
                        icon: '&#xe60e;',
                        color: '#00d042',
                        body: [
                            '结合企业多维度数据',
                            '获悉信息',
                        ],
                        path: '/home?recommend_type=business',
                    },
                    {
                        name: '投标方案模板及制作',
                        icon: '&#xe7ba;',
                        color: '#da35cb',
                        body: [
                            '投标方案模板一键下载',
                            '且引用云盘实现资源共享',
                        ],
                        path: '/document',
                    },
                    {
                        name: '数据报表',
                        icon: '&#xe623;',
                        color: '#5951ff',
                        body: [
                            '行业地区招投标数据报表',
                            '轻松获取',
                        ],
                        path: '/tools/data-export?data=info',
                    },
                    {
                        name: '数据统计',
                        icon: '&#xe614;',
                        color: '#ff5050',
                        body: [
                            '历年招投标数据分析',
                            '洞察市场变化',
                        ],
                        path: '/tools/data-export',
                    },
                ],
            },
        ],

        bigDataData: {
            title: '赋能大数据',
            list: [
                '日更数十万条项目新动态，第一时间获取项目信息',
                '项目信息全网覆盖，行业、地区招标数据多维度分析结果随意查看',
                '历年招投标数据分析，提前洞察市场变化',
            ],
            pic: [
                require('@images/index/block-bigData/3.png'),
                require('@images/index/block-bigData/2.png'),
                require('@images/index/block-bigData/1.png'),
            ],
        },
        analysisData: {
            title: '智能建模',
            list: [
                '打通企业库、项目库、招标库多源数据，智能建模，精准推送项目信息',
                '结合多维度数据，构建企业画像分析',
                '上下游关联客户数据全面获取，人脉圈轻松建立',
            ],
            pic: [
                require('@images/index/block-analysis/3.png'),
                require('@images/index/block-analysis/2.png'),
                require('@images/index/block-analysis/1.png'),
            ],
        },
        toolData: {
            title: '精准推荐优质工具及服务',
            list: [
                '优质三方投标工具及权威投标网站导航，满足高效办公所需',
                '标书检查、标书制作、电子投标服务等，覆盖投标全场景',
                '投标方案库涵盖全网资源，且引用云盘实现资源共享',
            ],
            pic: [
                require('@images/index/block-tool/3.png'),
                require('@images/index/block-tool/2.png'),
                require('@images/index/block-tool/1.png'),
            ],
        },

        fixedDownloadController: false,
        esiv_t: null,
        esiv_b: null,
    }),
    computed: {
        
    },
    mounted() {
        if (process.browser) {
            const {WOW} = require('wowjs');

            this.wow = new WOW({
                scrollContainer: '#__layout',
            });

            this.wow.init();

            this.$nextTick(() => {
                this.wow.sync();
            });
        }

        var t_state = true,
            b_state = false;

        this.esiv_t = new ElScrollInView({
            el: '#btn-downloads-t',
            root: '#__layout',
            handler: type => {
                t_state = type;

                this.fixedDownloadController = (!t_state && !b_state);
            },
        });

        this.esiv_b = new ElScrollInView({
            el: '#btn-downloads-b',
            root: '#__layout',
            handler: type => {
                b_state = type;

                this.fixedDownloadController = (!t_state && !b_state);
            },
        });
    },
    beforeDestroy() {
        this.wow.stop();

        this.wow = null;

        this.esiv_t.destroy();
        this.esiv_b.destroy();
    },

}
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';  

    .index{
        margin: -20px 0;
    }

    .banner{
        background: #f7f9ff;
        height: 500px;
        overflow: hidden;
        background-position: center center;

        .content{
            height: 100%;

            .slogan{
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);

                h1 {
                    font-size: 36px;
                    color: #131415;
                }

                .sub{
                    font-size: 20px;
                    color: #515151;
                    margin-top: 30px;
                }

                .btn-row{
                    font-size: 20px;
                    color: white;
                    margin-top: 30px;

                    .btn-item{
                        display: inline-block;
                        padding: 14px 30px;
                        cursor: pointer;
                        border-radius: 4px;
                        transition: all .1s;
                        line-height: 1em;
                        border: 1px solid $primaryColor;

                        background: white;
                        color: $primaryColor;

                        & + .btn-item{
                            margin-left: 20px;
                        }

                        &:hover{
                            background: $primaryColor8;
                            color: white;
                        }

                        &.primary{
                            background: $primaryColor;
                            color: white;

                            &:hover{
                                background: $primaryColor8;
                                border-color: $primaryColor8;
                            }
                        }
                    }
                }
            }
        }

        .icon{
            width: 663px;
            height: 100%;
            float: right;
            position: relative;

            img{
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
            }

            .breath:nth-child(1) {
                animation: breath 5s 1s infinite;
            }

            .breath:nth-child(2) {
                animation: breath 5s infinite;
            }
        }
    }

    .bg-light{
        background: white;
    }

    .section{
        padding: 30px 0 56px;

        .section-title{
            font-size: 36px;
            color: #333;
            margin-bottom: 56px;
            text-align: center;
        }
    }

    .positive{
        & > .content{
            overflow: visible;
        }

        .info-row{
            .title{
                text-align: center;
                font-size: 18px;
                color: #252525;
                font-weight: bold;
            }

            .list{
                overflow: visible;
                display: flex;
                margin-top: 34px;

                .list-item{
                    flex: 1;
                    box-shadow: 0px 0px 11px 0px #cee2ff;
                    border-radius: 3px;
                    padding: 12px 8px 28px;
                    cursor: pointer;
                    color: #252525;
                    transition: .1s all;

                    & + .list-item{
                        margin-left: 8px;
                    }

                    .head{
                        display: flex;
                        color: inherit;
                        font-size: 15px;
                        font-weight: bold;
                        align-items: center;

                        i{
                            margin-right: 4px;
                        }
                    }

                    .body{
                        margin-top: 22px;
                        line-height: 24px;
                        color: inherit;
                        padding: 0 2px;
                    }

                    &:before{
                        content: '';
                        display: block;
                        width: 100%;
                        height: 0;
                        background: $primaryColor;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        transition: .1s all;
                        z-index: 0;
                    }

                    &:hover{
                        color: white !important;

                        &:before{
                            height: 100%;
                        }
                    }
                }
            }

            & + .info-row{
                margin-top: 40px;
            }
        }
    }

    .wxmp-con {
        text-align: center;

        img{
            width: 200px;
        }

        .text{
            margin-top: 4px;
            font-size: 12px;
            color: #595959;
        }
    }

    @keyframes breath {
        0% {transform: scale(1);}
        50% {transform: scale(.9);}
        100% {transform: scale(1);}
    }
</style>
 